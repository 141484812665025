import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

export default makeStyles((theme: Theme) =>
  createStyles({
    loginView: {
      padding: 0,
      display: 'grid',
      gridTemplateColumns: '50% 50%',
      gridTemplateRows: '100vh',
      overflow: 'hidden',
      alignItems: 'stretch',
      backgroundColor: '#fafafa',
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: '1fr'
      },
      ...theme.custom?.loginView?.loginView as CSSProperties
    },

    languageSelector: {
      width: '50%',
      display: 'flex',
      justifyContent: 'flex-end',
      position: 'absolute',
      borderBottom: '1px solid rgba(0,0,0,.15)',
      zIndex: 1
    },

    error: {
      width: 'calc(50% - 1rem)',
      margin: '3.5rem .5rem .5rem .5rem',
      position: 'absolute'
    },

    loginContainer: {
      overflow: 'auto',

      // We are forced to use 'importants', because of poor styling in auth0lock,
      // othervice we would have to use cloned nesting.
      '& .auth0-lock': {
        zIndex: '0 !important'
      },

      '& .auth0-lock-center': {
        height: '100% !important',
        display: 'flex !important',
        padding: '60px 0 0 0 !important'
      },

      '& .auth0-lock-header-bg': {
        background: 'rgba(0,0,0,.03) !important',
        ...theme.custom?.loginView?.auth0Lock?.headerBackGround
      },

      '& .auth0-lock-header-bg-blur': {
        display: 'block !important',
        filter: 'none !important',
        "background-size": '0 0 !important',
      },

      '& form': {
        pointerEvents: 'auto',
        border: '1px solid rgba(0,0,0,.1)'
      },

      '& .auth0-lock-header-logo': {
        maxWidth: '100%',
        height: 'auto !important',
        maxHeight: '100%'
      },

      '& .auth0-lock-opened-in-frame': {
        pointerEvents: 'none !important'
      },

      '& .auth0-lock-social-button': {
        backgroundColor: 'white !important',
        background: 'white !important',
        border: `2px solid ${theme.palette.primary.main} !important`,

        '&:hover': {
          backgroundColor: `${theme.palette.primary.main} !important`,

          '& .auth0-lock-social-button-text': {
            color: `white !important`,
            backgroundColor: 'rgba(0, 0, 0, .1) !important'
          }
        }
      },

      '& .auth0-lock-social-button-text': {
        color: `${theme.palette.primary.main} !important`,
        backgroundColor: 'white !important',
        textAlign: 'center !important',
        padding: '0 !important'
      },

      '& .auth0-lock-social-button-icon': {
        display: 'none'
      },

      '& .auth0-lock-cred-pane-internal-wrapper': {
        minHeight: 'auto !important',
        height: 'auto !important'
      },

      '& .auth0-lock-input-block': {
        marginBottom: '10px !important'
      },

      '& .auth0-lock-submit, .auth0-lock-social-button, form': {
        borderRadius: '0 !important'
      },

      '& .auth0-label-submit': {
        ...theme.custom?.loginView?.auth0Lock?.loginButtonLabel
      }
    },

    '@media screen and (max-width: 959px)': {
      languageSelector: {
        width: '100%'
      },
      error: {
        width: 'calc(100% - 1rem)'
      }
    }
  })
);
